var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['validation-text', _vm.hasError ? 'error' : null],attrs:{"id":_vm.id}},[_c('div',{staticClass:"validation-text-wrapper"},[(_vm.left.clazz || _vm.left.url)?_c('span',{class:[
        'icon left',
        _vm.left.clazz ? _vm.left.clazz : null,
        _vm.isActive ? 'active' : '',
        _vm.hasError ? '' : 'ok',
        _vm.left.isOn ? 'on' : '',
        _vm.left.type
      ],style:(_vm.left.url ? { backgroundImage: 'url(' + _vm.left.url + ')' } : null),on:{"click":function($event){return _vm.onClickIcon(_vm.left, 'left')}}}):_vm._e(),(((_vm.attrs).type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],ref:"input",attrs:{"autocomplete":"off","novalidate":"","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"input":_vm.input,"change":[function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}},_vm.change],"focus":function($event){_vm.isActive = true},"blur":function($event){_vm.isActive = false}}},'input',_vm.attrs,false)):(((_vm.attrs).type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],ref:"input",attrs:{"autocomplete":"off","novalidate":"","type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"input":_vm.input,"change":[function($event){_vm.value=null},_vm.change],"focus":function($event){_vm.isActive = true},"blur":function($event){_vm.isActive = false}}},'input',_vm.attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],ref:"input",attrs:{"autocomplete":"off","novalidate":"","type":(_vm.attrs).type},domProps:{"value":(_vm.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.value=$event.target.value},_vm.input],"change":_vm.change,"focus":function($event){_vm.isActive = true},"blur":function($event){_vm.isActive = false}}},'input',_vm.attrs,false)),(_vm.right.clazz || _vm.right.url)?_c('span',{class:[
        'icon right',
        _vm.right.clazz ? _vm.right.clazz : null,
        _vm.isActive ? 'active' : '',
        _vm.hasError ? '' : 'ok',
        _vm.right.isOn ? 'on' : '',
        _vm.right.type
      ],style:(_vm.right.url ? { backgroundImage: 'url(' + _vm.right.url + ')' } : null),on:{"click":function($event){return _vm.onClickIcon(_vm.right, 'right')}}}):_vm._e()]),(_vm.hasError)?_c('div',{class:['hint', _vm.isActive ? 'active' : '']},[_c('ul',[(_vm.error)?_c('li',{staticClass:"error"}):_vm._e(),_vm._l((_vm.checkpoints),function(cp,c){return _c('li',{key:_vm.id + 'result' + c,class:cp.result ? 'ok' : 'bad'},[(cp.title)?_c('span',{domProps:{"innerHTML":_vm._s(cp.title + ':')}}):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(cp.result ? cp.ok : cp.bad)}})])})],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }