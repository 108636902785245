<template>
  <div :id="id" :class="['validation-text', hasError ? 'error' : null]">
    <div class="validation-text-wrapper">
      <span
        v-if="left.clazz || left.url"
        :class="[
          'icon left',
          left.clazz ? left.clazz : null,
          isActive ? 'active' : '',
          hasError ? '' : 'ok',
          left.isOn ? 'on' : '',
          left.type
        ]"
        :style="left.url ? { backgroundImage: 'url(' + left.url + ')' } : null"
        @click="onClickIcon(left, 'left')"
      ></span>
      <input
        ref="input"
        v-model="value"
        v-bind="attrs"
        autocomplete="off"
        novalidate
        @input="input"
        @change="change"
        @focus="isActive = true"
        @blur="isActive = false"
      />
      <span
        v-if="right.clazz || right.url"
        :class="[
          'icon right',
          right.clazz ? right.clazz : null,
          isActive ? 'active' : '',
          hasError ? '' : 'ok',
          right.isOn ? 'on' : '',
          right.type
        ]"
        :style="
          right.url ? { backgroundImage: 'url(' + right.url + ')' } : null
        "
        @click="onClickIcon(right, 'right')"
      ></span>
    </div>
    <div v-if="hasError" :class="['hint', isActive ? 'active' : '']">
      <ul>
        <li v-if="error" class="error"></li>
        <li
          v-for="(cp, c) in checkpoints"
          :key="id + 'result' + c"
          :class="cp.result ? 'ok' : 'bad'"
        >
          <span v-if="cp.title" v-html="cp.title + ':'"></span>
          <span v-html="cp.result ? cp.ok : cp.bad"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as utils from '../service/utils';
export default {
  inheritAttrs: false,
  props: {
    leftIcon: { type: String, default: null },
    rightIcon: { type: String, default: null },
    leftIconType: { type: String, default: null },
    rightIconType: { type: String, default: null },
    dict: { type: Array, default: () => [] },
    checkpoints: { type: Array, default: () => [] }, // { check:function(value){return true/false}, title:String, good:String,bad: String}
    error: { default: null }
  },

  data() {
    return {
      id: null,
      defaultType: null,
      attrs: {},
      left: { url: null, clazz: null, isOn: false, type: null },
      right: { url: null, clazz: null, isOn: false, type: null },
      value: '',
      isActive: false,
      checkpointError: true
    };
  },
  computed: {
    hasError() {
      return (
        this.checkpoints.length > 0 && (this.error || this.checkpointError)
      );
    }
  },
  mounted() {
    for (let key in this.$attrs) {
      switch (key) {
        case 'id':
          this.id = this.$attrs[key];
          break;
        default:
          this.$set(this.attrs, key, this.$attrs[key]);
      }
    }
    this.defaultType = this.attrs.type;
    this.left.type = this.leftIconType;
    this.right.type = this.rightIconType;
    if (this.leftIcon && utils.likeURL(this.leftIcon)) {
      this.left.url = this.leftIcon;
    } else {
      this.left.clazz = this.leftIcon;
    }
    if (this.rightIcon && utils.likeURL(this.rightIcon)) {
      this.right.url = this.rightIcon;
    } else {
      this.right.clazz = this.rightIcon;
    }
    this.check();
  },
  methods: {
    onClickIcon(icon, pos) {
      this.$refs.input.focus();
      switch (icon.type) {
        case 'button':
          this.$emit(pos + 'Click', this.child_num);
          break;
        case 'toggle':
          icon.isOn = !icon.isOn;
          if (this.defaultType === 'password') {
            if (icon.isOn) {
              this.attrs.type = 'text';
              this.$emit(pos + 'On', this.child_num);
            } else {
              this.attrs.type = 'password';
              this.$emit(pos + 'Off', this.child_num);
            }
          }
          break;
        default:
      }
    },
    check() {
      this.checkpointError = false;
      for (let i in this.checkpoints) {
        const cp = this.checkpoints[i];
        cp.result = cp.check(this.value, this);
        if (!cp.result) {
          this.checkpointError = true;
        }
      }
      this.$emit('validate', this.checkpointError);
    },
    input(evt) {
      this.check();
      this.$emit('input', evt.target.value);
    },
    change(evt) {
      this.$emit('change', evt.target.value);
    }
  }
};
</script>

<style scoped>
.validation-text {
  font-size: inherit;
  background-color: #fff;
  border: 1px solid #1bb222;
  border-radius: 0.4em;
}
.error {
  border-color: #bc1313;
}

.validation-text-wrapper {
  display: flex;
  align-items: center;
}
input {
  display: block;
  padding: 0.5em 0.5em;
  flex: 1;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: inherit;
}

.icon.left {
  margin-right: -0.5em;
}
.icon.right {
  margin-left: -0.5em;
}

.hint.active {
  top: 0;
  opacity: 1;
}

.hint {
  top: 1em;
  opacity: 0;
  position: relative;
  pointer-events: none;
  user-select: none;
  transition: 0.3s;
}

ul {
  position: absolute;
  top: 0.5em;
  width: 100%;
  background-color: #ffffe0;
  border-radius: 0.25em;
  padding: 0.5em;
  box-shadow: 1px 2px 3px 0 #0004;
  font-size: 50%;
}

li {
  padding: 0.25em 0;
}

.ok {
  color: #1bb222;
}

.bad {
  color: #bc1313;
}

ul:after {
  content: '';
  position: absolute;
  top: -2em;
  left: 2em;
  border: 1em solid transparent;
  border-bottom: 1em solid #ffffe0;
}

.icon {
  display: block;
  width: 2em;
  height: 2em;
  transition: 0.1s;
}

.toggle:hover,
.button:hover {
  opacity: 1;
  transform: scale(1.1);
}
.toggle:active,
.button:active {
  opacity: 1;
  transform: scale(0.9);
}
</style>
